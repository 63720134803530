<template>
  <div class="card-content table-card-content">
    <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    <small v-if="pspdList.length > 0" class="page-info is-pulled-right"
      ><strong>{{ pagination.startRowNum }}</strong> -
      <strong>{{ pagination.startRowNum + pspdList.length - 1 }}</strong>
      dari <strong>{{ pagination.total }}</strong></small
    >
    <div class="cleardiv"></div>
    <div class="box-container">
      <box v-for="pspd in pspdList" :key="pspd.id" :title="pspd.nama"></box>
      <box v-if="pspdList.length == 0">
        <template v-slot:content>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Belum ada mahasiswa yang terdaftar pada kelompok ini.</p>
            </div>
          </section>
        </template>
      </box>
    </div>
    <!-- <b-table striped hoverable :data="pspdList" :mobile-cards="false">
      <b-table-column field="no" label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
      <b-table-column field="pspd" label="Mahasiswa" v-slot="props">{{ props.row.nama }}</b-table-column>
      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <p>Tidak ada data mahasiswa terdaftar.</p>
        </div>
      </template>
    </b-table>-->
    <b-pagination
      v-if="pagination.showPagination"
      :total="pagination.total"
      :per-page="pagination.limit"
      :current.sync="pagination.page"
      order="is-centered"
      icon-pack="fas"
      @change="pageChange"
    ></b-pagination>
    <!-- <b-loading :is-full-page="false" :active.sync="loading"></b-loading> -->
  </div>
</template>

<script>
import PSPDList from "../models/pspdList.js";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";

export default {
  name: "PpdhTable",
  props: {
    kelompok: null,
  },
  mixins: [onlineCheckMixin, paginationMixin],
  data() {
    this.listMdl = new PSPDList(this.kelompok.id);
    return this.listMdl.getObservables();
  },
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
  },
  methods: {
    fetchData() {
      this.listMdl.load(this.onLoaded);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
.box:not(:last-child) {
  margin-bottom: unset;
}

.page-info {
  margin-top: 10px;
  margin-right: 10px;
}

.cleardiv {
  clear: both;
}
</style>